import React, {useMemo} from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import MenuItem from './FaqMenuItem';
import {Center} from '../../Components/Layout/ContentWrapper';
import {Row, Col, Drawer} from 'antd';
import {Heading} from '../../Widgets';
import {ToggleDrawer} from '../../Widgets';
import {Info} from '@styled-icons/material';
import Preview from 'rev.sdk.js/Components/RichTextPreview';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import {normalizePath} from '../../Utils/pathUtil';
const appConfig = require('../../data.json');

// labels structure
// {
//   display: "",
//   name: "",
//   children: [
//     {
//       display: "",
//       name: "",
//     },
//     ...
//   ]
// }

function Group(props) {
  const {records} = props;
  const [selectedIdx, setSelectedIdx] = React.useState(0);

  React.useEffect(() => {
    setSelectedIdx(0);
  }, [records]);

  return (
    <GroupWrapper>
      {records.map((record, idx) => {
        const selected = idx === selectedIdx;
        return (
          <section key={record.id}>
            <header
              className={`${selected ? 'selected' : ''}`}
              onClick={() => setSelectedIdx(idx)}>
              <div style={{flex: 1}}>Q : {record.title}</div>
              {selected ? (
                <CaretUpOutlined
                  style={{fontSize: 16, color: appConfig.colors.main}}
                />
              ) : (
                <CaretDownOutlined
                  style={{fontSize: 16, color: appConfig.colors.text}}
                />
              )}
            </header>

            {selected && (
              <div className={`content ${selected ? 'visible' : ''}`}>
                <Preview content={record.content} />
              </div>
            )}
          </section>
        );
      })}
    </GroupWrapper>
  );
}

const GroupWrapper = styled.div`
  border-top: solid 2px #eee;
  border-bottom: solid 2px #eee;
  margin-bottom: 40px;

  & > section {
    & > header {
      padding: 20px;
      color: ${appConfig.colors.text};
      font-weight: 600;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
    }

    & > header.selected {
      color: ${appConfig.colors.main};
    }

    & > .content {
      padding: 20px;
      background-color: #f9f9f9;
      transition: 200ms;
      opacity: 0;
      height: 0px;
      pointer-events: none;

      & p,
      blockquote,
      li,
      a {
        font-size: ${appConfig.fontSize.md}px;
        line-height: 1.75;
      }
    }

    & > .content.visible {
      opacity: 1;
      height: auto;
      pointer-events: auto;
    }
  }
`;

const styles = {
  major: {
    padding: 15,
    margin: 5,
    color: appConfig.colors.text,
    backgroundColor: appConfig.colors.second,
  },
  sub: {
    padding: '5px 0',
    color: '#989898',
    borderBottom: 'solid 2px #989898',
    margin: 10,
  },
};

const Menu = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: ${appConfig.fontSize.md}px;
`;

function FaqPage(props) {
  const app = React.useContext(AppContext.Context);
  const [selected, setSelected] = React.useState({
    major: null,
    sub: null,
  });
  const [records, setRecords] = React.useState([]);
  const {faqLabels: labels} = app.state;
  const [showDrawer, setShowDrawer] = React.useState(false);

  const subLabels = useMemo(() => {
    if (selected) {
      const label = labels.find((l) => l.name === selected.major);
      if (label) {
        return label.children;
      }
    }
    return [];
  }, [labels, selected]);

  React.useEffect(() => {
    if (labels.length > 0 && labels[0].children.length > 0) {
      setSelected({
        major: labels[0].name,
        sub: labels[0].children[0],
      });
    }
  }, [labels]);

  React.useEffect(() => {
    async function fetchData() {
      if (selected.sub && selected.major) {
        app.actions.setLoading(true);
        let params = {
          $and: [
            {label: 'FAQ'},
            ...(selected && [{label: selected.major}]),
            selected ? {label: selected.sub.name} : {label: 'none'},
          ],
        };

        setRecords(await app.actions.getBlogs({query: params}));
        app.actions.setLoading(false);
      }
    }

    fetchData();
  }, [app.actions, selected]);

  return (
    <Wrapper>
      <div
        style={{
          paddingTop:
            50 +
            (appConfig.navbarHeightExcludePages.includes(
              normalizePath(props.location.pathname),
            )
              ? appConfig.navbarHeight
              : 0),
          paddingBottom: 30,
          backgroundColor: appConfig.topSectionBackgroundColor,
        }}>
        <h2
          style={{
            textAlign: 'center',
            color: appConfig.colors.textSecond,
          }}>
          {appConfig.mapping.faq}
        </h2>
        <Center>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {labels.map((major, idx) => (
              <Menu
                key={idx}
                onClick={() => {
                  let sub =
                    major.children.length > 0 ? major.children[0] : null;
                  setSelected({major: major.name, sub});
                }}
                style={{
                  ...styles.major,
                  color:
                    selected.major === major.name ? '#fff' : styles.major.color,
                  backgroundColor:
                    selected.major === major.name
                      ? appConfig.colors.main
                      : styles.major.backgroundColor,
                }}>
                {major.display}
              </Menu>
            ))}
          </div>
        </Center>
      </div>

      <Center maxWidth={appConfig.blogMaxWidth}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 30,
          }}>
          {subLabels.map((sub, idx) => (
            <Menu
              key={idx}
              onClick={() => setSelected((prev) => ({...prev, sub}))}
              style={{
                ...styles.sub,
                color:
                  selected.sub?.name === sub.name
                    ? appConfig.colors.main
                    : styles.sub.color,
                borderBottomColor:
                  selected.sub?.name === sub.name
                    ? appConfig.colors.main
                    : styles.sub.color,
              }}>
              {sub.display}
            </Menu>
          ))}
        </div>

        <Group key={selected.sub} records={records} />
      </Center>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  @media only screen and (max-width: ${(props) => props.maxWidth}px) {
    padding: 20px;
  }
`;

export default FaqPage;
